import { useTranslations } from 'next-intl';
import { Link } from '@/i18n/routing';
import useNavlinks from './useNavLinks';

export default function Nav() {
  const t = useTranslations('common');
  const navLinks = useNavlinks();
  return (
    <nav className="hidden md:flex justify-center md:gap-6">
      {navLinks.map((item) => {
        return (
          <Link
            key={item.label}
            // @ts-expect-error
            href={item.href}
            className="text-center text-base font-medium tracking-tight text-gray-500 py-3 no-underline hover:text-gray-900"
          >
            {t(item.label)}
          </Link>
        );
      })}
    </nav>
  );
}
