'use client';

import type { ReactElement } from 'react';
import RightButtons from 'src/components/header/RightButtons';
import Logo from './Logo';
import Nav from './Nav';

interface Props {
  className?: string;
  renderLeft?: ReactElement<any>;
  renderCenter?: ReactElement<any>;
  renderRight?: ReactElement<any>;
  renderTop?: ReactElement<any>;
  renderBottom?: ReactElement<any>;
}

export default function Header(props: Props) {
  function renderLeft() {
    return props.renderLeft || <Logo />;
  }

  function renderCenter() {
    return props.renderCenter || <Nav />;
  }

  function renderRight() {
    return props.renderRight || <RightButtons />;
  }

  function renderTop() {
    return props.renderTop || null;
  }

  function renderBottom() {
    return props.renderBottom || null;
  }

  return (
    <header className="sticky top-0 z-20">
      <div
        className={
          props.className ||
          'border-b border-greyscale-separator bg-white'
        }
      >
        {renderTop()}
        <div className="container mx-auto flex items-center justify-between gap-6 py-4 px-4 lg:px-6 xl:px-8">
          <div className="md:min-w-[10rem]">{renderLeft()}</div>
          <div className="flex-auto">{renderCenter()}</div>
          <div className="md:min-w-[10rem] flex justify-end">
            {renderRight()}
          </div>
        </div>
        {renderBottom()}
      </div>
    </header>
  );
}
