import {IMGPW} from '@/config';
import {Link} from '@/i18n/routing';
import Image from 'next/image';

export default function Logo() {
  return (
    <Link href="/">
      <div className="md:block hidden">
        <div className="relative h-10 w-32">
          <Image
            fill
            src={IMGPW + '/winelivery.png'}
            alt="Logo Winelivery"
            className="object-contain"
          />
        </div>
      </div>
      <div className="md:hidden">
        <div className="relative h-10 w-10">
          <Image
            fill
            src={IMGPW + '/logo_winelivery_small.png'}
            alt="Logo Winelivery"
            className="object-contain"
          />
        </div>
      </div>
    </Link>
  );
}
